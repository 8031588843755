

























































































































































































































































































































































































import { Component, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Table from "@/components/Table.vue";
import { namespace } from "vuex-class";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EducationEventBookForm from "@/views/Student/EducationEvent/EducationEventBookForm.vue";
import { mixins } from "vue-class-component";
import AbortButton from "@/components/Button/AbortButton.vue";
import EducationEventNoteForm from "@/views/Student/EducationEvent/EducationEventNoteForm.vue";
import EducationDocumentPreview from "@/views/Student/EducationEvent/EducationDocumentPreview.vue";
import CancelInvoiceModal from "@/views/AutomaticPaymentsOverview/CancelInvoiceModal.vue";
import { CANCELABLE_INVOICE } from "@/constants/PaymentDocumentType";
import FileXmarkButton from "@/components/Button/FileXmarkButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import BasicFeeAndLearningMaterialForm from "@/views/Student/EducationEvent/BasicFeeAndLearningMaterialForm.vue";
import i18n from "@/i18n";
import { EXTERNAL_SERVICES_PACK, NOTE, PAYMENT_REQUEST, PROOF_OF_TRAINING, STUDENT_DOCUMENT, THEORY_LESSON } from "@/constants/EducationEventTypes";
import EducationTheoryLessonPreview from "@/views/Student/EducationEvent/Details/EducationTheoryLessonPreview.vue";
import EducationOtherCostsPreview from "@/views/Student/EducationEvent/Details/EducationOtherCostsPreview.vue";
import EducationNotesPreview from "@/views/Student/EducationEvent/Details/EducationNotesPreview.vue";
import { v4 as uuidv4 } from "uuid";
import DrivingLessonDetails from "@/views/Student/EducationEvent/Details/DrivingLessonDetails.vue";
import PracticalExamDetails from "@/views/Student/EducationEvent/Details/PracticalExamDetails.vue";
import InvoicePreview from "@/views/Student/EducationEvent/Details/InvoicePreview.vue";
import InvoicePreviewPdfDocument from "@/views/Student/EducationEvent/Details/InvoicePreviewPdfDocument.vue";
import TheoryExamDetails from "@/views/Student/EducationEvent/Details/TheoryExamDetails.vue";
import EducationClassicPayPreview from "@/views/Student/EducationEvent/Details/EducationClassicPayPreview.vue";
import EducationStudentDocumentPreview from "@/views/Student/EducationEvent/Details/EducationStudentDocumentPreview.vue";
import ProductBookingPreview from "@/views/Student/EducationEvent/Details/ProductBookingPreview.vue";
import EducationEventTypeMixin from "@/mixins/EducationEventTypeMixin";
import LivePayDetails from "@/views/Student/EducationEvent/Details/LivePayDetails.vue";
import MailServicePreview from "@/views/Student/EducationEvent/Details/MailServicePreview.vue";
import EducationProofOfTrainingPreview from "@/views/Student/EducationEvent/Details/EducationProofOfTrainingPreview.vue";
import EducationPaymentPreview from "@/views/Student/EducationEvent/Details/EducationPaymentPreview.vue";
import moment from "moment";
import { BASIC_FEE_STANDARD, LEARNING_MATERIAL } from "@/constants/Products";
import ExternalServiceForm from "@/views/Student/EducationEvent/ExternalServiceForm.vue";
import ExtenalServicePreview from "@/views/Student/EducationEvent/Details/ExtenalServicePreview.vue";
import { downloadXlsXFile } from "@/utils/File";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import MessagePreview from "@/views/Student/EducationEvent/Details/MessagePreview.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import { IBasicUser } from "@/interfaces/IBasicUser";

const EducationEventModule = namespace("education-event");
const StudentBookedProductModule = namespace("student-booked-product");
const CustomBookedProductModule = namespace("custom-booked-product");
const StudentModule = namespace("student");
const StudentNoteModule = namespace("student-note");
const VatModule = namespace("vat");
const ExternalServicesModule = namespace("external-services");
const StudentDocumentModule = namespace("student-document");
const PaymentsModule = namespace("payments");

const BASIC_FEE = "basicFee";
const TEACHING_MATERIAL = "teachingMaterial";
const BOOKING = "booking";

const RADIO_OPTIONS = [
  { text: i18n.t("theory_course.basic_fee"), value: BASIC_FEE },
  {
    text: i18n.t("theory_course.teaching_material"),
    value: TEACHING_MATERIAL,
  },
  {
    text: `${i18n.tc("calendar.other", 1)} ${i18n.t("automatic_payment.subject_type_app")}`,
    value: BOOKING,
  },
  { text: i18n.t("general.note"), value: NOTE },
  { text: i18n.t("general.external_service"), value: EXTERNAL_SERVICES_PACK },
];

@Component({
  components: {
    ExtenalServicePreview,
    MailServicePreview,
    LivePayDetails,
    TheoryExamDetails,
    PracticalExamDetails,
    DrivingLessonDetails,
    BasicFeeAndLearningMaterialForm,
    FileXmarkButton,
    CancelInvoiceModal,
    AbortButton,
    EducationEventBookForm,
    EducationEventNoteForm,
    CreateButton,
    Actions,
    Table,
    FscSimpleCard,
    FscPageHeader,
    EducationDocumentPreview,
    SaveButton,
    EducationTheoryLessonPreview,
    EducationOtherCostsPreview,
    EducationNotesPreview,
    InvoicePreview,
    InvoicePreviewPdfDocument,
    EducationClassicPayPreview,
    EducationStudentDocumentPreview,
    ProductBookingPreview,
    EducationProofOfTrainingPreview,
    EducationPaymentPreview,
    ExternalServiceForm,
    RemoveButton,
    FscModal,
    ActionButton,
    MessagePreview,
    FscMultiselect,
  },
})
export default class EducationEventList extends mixins(EducationEventTypeMixin) {
  public name = "EducationEventList";

  public radioSelected = "";

  protected selectedClass: Array<string> = [];

  private selectedLicenseClasses: Array<string> = [];
  private filterByTheory = false;

  protected educationEventsFields = [
    {
      key: "date",
      label: this.$t("calendar.form_date"),
      sortable: false,
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
      sortable: false,
    },
    {
      key: "status",
      label: this.$t("general.status"),
      sortable: false,
      class: "text-center",
    },
    {
      key: "description",
      label: this.$t("roles.description"),
      sortable: false,
    },
    {
      key: "instructor",
      label: this.$t("sidebar.instructors"),
      sortable: false,
    },
    {
      key: "licenseClass",
      label: this.$t("calendar.class"),
      sortable: false,
    },
    {
      key: "duration",
      label: "Min.",
      sortable: false,
    },
    {
      key: "amountToPay",
      label: this.$t("students.amount_to_pay"),
      sortable: false,
      thStyle: "text-align: end",
    },
    {
      key: "amountPaid",
      label: this.$t("students.amount_paid"),
      sortable: false,
      thStyle: "text-align: end",
    },
  ];

  protected noteData = {
    student: {
      id: null,
      firstName: "",
      lastName: "",
      initials: "",
    },
    note: "",
    createdOn: null,
    date: null,
  };

  public externalServiceData = {
    studentEducationId: {
      id: null,
      mainLicenseClass: null,
    },
    basicTheoryLessons: null,
    classSpecificTheoryLessons: null,
    drivingLessons: null,
    drivingLessonsMinutes: 0,
    basicDrivingTask: null,
    basicDrivingTaskMinutes: 0,
    crossCountryLesson: null,
    crossCountryLessonMinutes: 0,
    motorwayLessons: null,
    motorwayLessonsMinutes: 0,
    nightLessons: null,
    nightLessonsMinutes: 0,
    b197TestLessons: null,
    b197TestLessonsMinutes: 0,
    instructionLessons: null,
    instructionLessonsMinutes: 0,
    manualTransmissionLessons: null,
    manualTransmissionLessonsMinutes: 0,
    simulatorLessons: null,
    simulatorLessonsMinutes: 0,
  };

  public prices: Array<any> = [];

  public selectedEducationEvent: any = "";

  @StudentBookedProductModule.Action("create")
  public studentBookedProductCreate: any;

  @StudentBookedProductModule.Getter("getSuccess")
  public studentBookedProductSuccess: any;

  @StudentBookedProductModule.Getter("getIsLoading")
  public studentBookedProductLoading: any;

  @CustomBookedProductModule.Action("create")
  public customBookedProductCreate: any;

  @CustomBookedProductModule.Getter("getSuccess")
  public customBookedProductSuccess: any;

  @StudentNoteModule.Action("create")
  public createStudentNote: any;

  @StudentNoteModule.Getter("getSuccess")
  public studentCreatedNoteSuccess: any;

  @EducationEventModule.Action("cancelInvoice")
  protected cancelInvoice!: (invoiceForCancellationId: number) => Promise<void>;

  @EducationEventModule.Getter("getCancelInvoiceSuccess")
  protected getCancelInvoiceSuccess!: boolean;

  @VatModule.Action("findAll")
  public findAllVatOptions: any;

  @VatModule.Getter("getDataList")
  public vatOptions: any;

  @StudentModule.Getter("getSelectedRowName")
  public getSelectedRowName!: string;

  @ExternalServicesModule.Action("create")
  public createExternalService: any;

  @ExternalServicesModule.Getter("getSuccess")
  public externalServiceSuccess: any;

  @ExternalServicesModule.Getter("getIsLoading")
  public externalServiceLoading: any;

  @StudentDocumentModule.Action("updateDocumentVisible")
  public setDocumentVisible: any;

  @StudentDocumentModule.Action("updateInvoiceVisible")
  public setInvoiceVisible: any;

  @StudentDocumentModule.Getter("getSuccess")
  public visibleSuccess: any;

  @PaymentsModule.Action("create")
  public cancellingPayment: any;

  @PaymentsModule.Getter("getSuccess")
  public successCancelling: any;

  // protected cancelInvoiceModalId = "student-event-cancel-invoice-modal-id";
  protected deleteServiceModalId = "student-delete-service-modal-id";
  protected cancelLivePayModalId = "student-cancel-live-pay-modal-id";
  public deleteServiceMessage = "";

  protected modalPopover = `modal-popover-${this.uuid}`;
  public filterModal = `filterModal-${this.uuid}`;

  public get licenseClasses(): Array<any> {
    return this.studentEducations.map((education: any) => education.mainLicenseClass);
  }
  public selectedSearchByTheory = false;

  public get hasAppliedFilter() {
    return this.selectedClass?.length > 0 || this.selectedSearchByTheory;
  }

  public onAbortRadioSelect(): void {
    this.radioSelected = "";
    this.$bvModal.hide(this.modalPopover);
  }

  public onRadioSelectSubmit() {
    this.onCloseAllForms();
    switch (this.radioSelected) {
      case BOOKING:
        this.visibleBooking = true;
        break;
      case NOTE:
        this.visibleNote = true;
        break;
      case TEACHING_MATERIAL:
        this.visibleLearningMaterialForm = true;
        break;
      case BASIC_FEE:
        this.visibleBasicFeeForm = true;
        break;
      case EXTERNAL_SERVICES_PACK:
        this.visibleExternalServiceForm = true;
        break;
      default:
        break;
    }
    this.$bvModal.hide(this.modalPopover);
  }

  public fetchOnMounted(): void {
    this.fetchEducationEvent(this.studentId);
    this.fetchStudentEducations(this.studentId);
  }

  public fetchVats() {
    this.findAllVatOptions({
      resource: "vat-options",
      params: { archived: false },
    });
  }

  public mounted(): void {
    this.fetchOnMounted();
    this.selectedEducationEvent = this.eventsFilterOption[0];
    // Reset basic fee/learning material form products
    this.$watch(
      () => [this.visibleBasicFeeForm, this.visibleLearningMaterialForm],
      () => {
        this.prices = [];
        this.basicFeeProducts = [];
        this.learningMaterialProducts = [];
      }
    );
  }

  // public get headerLabel() {
  //   return this.getSelectedRowName || "";
  // }

  protected get studentId(): number {
    const { id } = this.$route.params;
    return Number(id);
  }

  protected onCloseEducationBook(): void {
    this.visibleBooking = false;
    this.radioSelected = "";
  }

  protected async fetchEducationEvent(studentId: number): Promise<void> {
    await this.fetchEducationEvents(studentId);
  }

  protected get studentEducationLicenseClassOption(): Array<any> {
    return this.studentEducations.map((edu: any) => {
      return {
        educationId: edu.id,
        licenseClass: edu.mainLicenseClass,
        costBearer: edu.costBearer,
      };
    });
  }

  protected get vatOption(): Array<any> {
    return this.vatOptions;
  }

  protected onAbort(): void {
    this.closeCurrentTab();
    this.$router.push({ name: "Students" });
  }

  protected onCloseNoteForm(): void {
    this.visibleNote = false;
    this.radioSelected = "";
  }

  public onSubmitExternalServiceForm(education: any): void {
    const externalService = {
      studentEducationId: education.id,
      basicTheoryLessons: this.externalServiceData.basicTheoryLessons,
      classSpecificTheoryLessons: this.externalServiceData.classSpecificTheoryLessons,
      drivingLessons: this.externalServiceData.drivingLessons,
      drivingLessonsMinutes: this.externalServiceData.drivingLessonsMinutes,
      basicDrivingTask: this.externalServiceData.basicDrivingTask,
      crossCountryLesson: this.externalServiceData.crossCountryLesson,
      motorwayLessons: this.externalServiceData.motorwayLessons,
      nightLessons: this.externalServiceData.nightLessons,
      b197TestLessons: this.externalServiceData.b197TestLessons,
      instructionLessons: this.externalServiceData.instructionLessons,
      manualTransmissionLessons: this.externalServiceData.manualTransmissionLessons,
      simulatorLessons: this.externalServiceData.simulatorLessons,
      basicDrivingTaskMinutes: this.externalServiceData.basicDrivingTaskMinutes,
      crossCountryLessonMinutes: this.externalServiceData.crossCountryLessonMinutes,
      motorwayLessonsMinutes: this.externalServiceData.motorwayLessonsMinutes,
      nightLessonsMinutes: this.externalServiceData.nightLessonsMinutes,
      b197TestLessonsMinutes: this.externalServiceData.b197TestLessonsMinutes,
      instructionLessonsMinutes: this.externalServiceData.instructionLessonsMinutes,
      manualTransmissionLessonsMinutes: this.externalServiceData.manualTransmissionLessonsMinutes,
      simulatorLessonsMinutes: this.externalServiceData.simulatorLessonsMinutes,
    };
    this.createExternalService({
      data: externalService,
      resource: "external-services-packs",
    });
  }

  @Watch("externalServiceSuccess", { immediate: true, deep: true })
  private onExternalEducationSuccess(educationSuccess: any): void {
    if (educationSuccess) {
      this.visibleExternalServiceForm = false;
      this.externalServiceData.studentEducationId = {
        id: null,
        mainLicenseClass: null,
      };
      this.externalServiceData.basicTheoryLessons = null;
      this.externalServiceData.classSpecificTheoryLessons = null;
      this.externalServiceData.drivingLessons = null;
      this.externalServiceData.drivingLessonsMinutes = 0;
      this.externalServiceData.basicDrivingTask = null;
      this.externalServiceData.crossCountryLesson = null;
      this.externalServiceData.motorwayLessons = null;
      this.externalServiceData.nightLessons = null;
      this.externalServiceData.b197TestLessons = null;
      this.externalServiceData.instructionLessons = null;
      this.externalServiceData.manualTransmissionLessons = null;
      this.externalServiceData.simulatorLessons = null;
      this.externalServiceData.basicDrivingTaskMinutes = 0;
      this.externalServiceData.crossCountryLessonMinutes = 0;
      this.externalServiceData.motorwayLessonsMinutes = 0;
      this.externalServiceData.nightLessonsMinutes = 0;
      this.externalServiceData.b197TestLessonsMinutes = 0;
      this.externalServiceData.instructionLessonsMinutes = 0;
      this.externalServiceData.manualTransmissionLessonsMinutes = 0;
      this.externalServiceData.simulatorLessonsMinutes = 0;
      this.radioSelected = "";
      this.fetchEducationEvent(this.studentId);
      this.fetchStudentEducations(this.studentId);
    }
  }

  protected onSubmitEducationNoteForm(): void {
    if (this.typeCreate()) {
      const note = {
        student: {
          id: this.noteData.student.id,
          firstName: this.noteData.student.firstName,
          lastName: this.noteData.student.lastName,
          initials: this.noteData.student.initials,
        },
        note: this.noteData.note,
        date: this.noteData.date,
      };
      this.createStudentNote({
        data: note,
        resource: "student-note",
      });
    }
  }

  @Watch("student", { immediate: true, deep: true })
  public onChangeStudentData(studentData: any): void {
    if (studentData) {
      this.noteData.student.id = studentData.id;
      this.noteData.student.firstName = studentData.firstName;
      this.noteData.student.lastName = studentData.lastName;
      this.noteData.student.initials = studentData.title;
    }
  }

  @Watch("studentCreatedNoteSuccess", { immediate: true, deep: true })
  private onNoteSuccess(noteSuccess: any): void {
    if (noteSuccess) {
      this.visibleNote = false;
      this.radioSelected = "";
      this.fetchEducationEvent(this.studentId);
      this.fetchStudentEducations(this.studentId);
    }
  }

  // protected get selectedIsCancelable(): boolean {
  //   return this.selectedRow?.id && CANCELABLE_INVOICE.includes(this.selectedRow?.educationEventType);
  // }

  // protected async onCancelInvoice(): Promise<void> {
  //   if (!this.selectedIsCancelable) return;
  //   this.$bvModal.show(this.cancelInvoiceModalId);
  // }

  // protected async okCancelInvoice(): Promise<void> {
  //   if (!this.selectedIsCancelable) return;
  //   await this.cancelInvoice(this.selectedRow.id);
  //
  //   if (this.getCancelInvoiceSuccess) {
  //     await this.fetchEducationEvent(this.studentId);
  //   }
  // }

  protected get radioOptions(): Array<any> {
    return RADIO_OPTIONS;
  }

  protected async onSubmitBasicFeeAndLearningMaterial(data: any): Promise<void> {
    await this.studentBookedProductCreate({
      resource: "student-booked-product",
      data: {
        licenseClass: data.studentEducation.licenseClass,
        studentEducationId: data.studentEducation.educationId,
        date: data.date,
        priceInEur: data.amount,
        product: data.product,
      },
    });
    if (this.studentBookedProductSuccess) {
      await this.fetchEducationEvent(this.studentId);
      if (this.visibleLearningMaterialForm) {
        (this.$refs.learningMaterialForm as BasicFeeAndLearningMaterialForm).resetFields();
      } else {
        (this.$refs.basicFeeForm as BasicFeeAndLearningMaterialForm).resetFields();
      }
    }
  }

  protected get uuid() {
    return uuidv4();
  }

  // protected get studentPracticalExam(): any {
  //   return this.practicalExam?.studentPracticalExams?.find((x: any) => {
  //     return x.student.id === this.studentId;
  //   });
  // }

  protected async refreshLivePayHandle(educationEventId: number, livePayPaymentId: number): Promise<void> {
    await this.livePayPaymentUpdateStatus(livePayPaymentId);
    if (this.getLivePayPaymentUpdateStatusSuccess) {
      this.fetchOnMounted();
      this.fetchLivePayPayment(educationEventId);
    }
  }

  protected async cancelLivePayPayment(): Promise<void> {
    const livePayPaymentId = this.livePayPayment?.id;
    if (livePayPaymentId) {
      await this.cancellingPayment({
        resource: `live-pay/payment/${livePayPaymentId}/cancel`,
      });
      if (this.successCancelling) {
        this.fetchOnMounted();
        this.fetchLivePayPayment(livePayPaymentId);
      }
    } else {
      this.$toasted.info("Live Pay Payment ID not selected!");
    }
  }

  protected get hasNotPermissionWrite(): boolean {
    return !this.$can(this.MODULE_CUSTOMERS_WRITE);
  }

  public get deleteServiceInfoMessage() {
    return this.deleteServiceMessage;
  }

  protected onRemoveService() {
    this.deleteServiceMessage = "";

    if (this.deleteServiceIsAllowed) {
      if (this.selectedRow && this.selectedRow.educationEventType === PAYMENT_REQUEST) {
        this.deleteServiceMessage = this.$tc("messages.delete_payment_request");
      } else if (
        (this.selectedRow && this.selectedRow.educationEventType === STUDENT_DOCUMENT && this.selectedRow.description !== "Ausbildungsvertrag") ||
        this.selectedRow.educationEventType === PROOF_OF_TRAINING
      ) {
        this.deleteServiceMessage = this.$tc("messages.delete_payment_request");
      } else if (this.selectedRow && this.selectedRow.description === "Ausbildungsvertrag" && this.selectedRow.educationEventType === STUDENT_DOCUMENT) {
        this.deleteServiceMessage = this.$tc("messages.delete_ausbildung");
      } else {
        this.deleteServiceMessage = this.$tc("messages.delete_service_agreement_message", 1);
      }
    } else {
      this.deleteServiceMessage = this.$tc("messages.delete_service_forbidden_message", 1);
    }

    this.$bvModal.show(this.deleteServiceModalId);
  }

  protected async triggerDeleteService() {
    this.$bvModal.hide(this.deleteServiceModalId);

    await this.onDeleteSelectedService();
    await this.fetchOnMounted();
    if (this.educationEvents.length === 1) {
      // if the last one: where 1 is the no-table-data-item
      this.onCloseAllForms();
    }
  }

  protected cancelDeleteService() {
    this.deleteServiceMessage = "";
    this.$bvModal.hide(this.deleteServiceModalId);
  }

  public onPrintEventsList() {
    downloadXlsXFile({
      method: "post",
      url: `education-events/student/export/excel?studentId=${this.studentId}`,
      data: this.filteredOptions,
    });
  }

  @Watch("selectedEducationEvent")
  public onSelectedEducationEventChange(educationEvent: any): void {
    if (educationEvent && educationEvent.educationEventType) {
      this.onCloseAllForms();
    }
  }

  public get hasPermission(): boolean {
    return this.$can(this.MODULE_PAYMENT_WRITE);
  }

  public onRefresh() {
    this.fetchOnMounted();
  }

  public async onSetDocumentVisible(document: any) {
    const options = {
      id: document.id,
      documentVisible: !document.driveBuzzVisible,
    };
    await this.setDocumentVisible(options);
  }

  public async onInvoiceVisible(invoice: any) {
    const options = {
      id: invoice.id,
      documentVisible: !invoice.driveBuzzVisible,
    };
    await this.setInvoiceVisible(options);
  }

  @Watch("visibleSuccess", { immediate: true, deep: true })
  public onVisibleDocumentSuccess(success: any) {
    if (success) {
      this.fetchOnMounted();
    }
  }

  public educationEventBookOnMounted() {
    this.fetchVats();
  }

  public invoicePreviewPdfDocumentOnMounted() {
    if (this.studentId) {
      this.fetchStudent(this.studentId);
    }
  }

  private getDescription(item: any) {
    if (item.educationEventType == THEORY_LESSON) {
      const plannedOrBooked = item.booked ? "(Gebucht)" : "(Geplant)";
      return `${item.description} ${plannedOrBooked}`;
    }
    return item.description;
  }

  // Clear filter
  public abortFilter() {
    this.selectedClass = [];
    this.selectedSearchByTheory = false;
    this.selectedLicenseClasses = [];
    this.filterByTheory = false;
  }

  // Set filter
  public triggerFiltering() {
    this.selectedLicenseClasses = this.selectedClass;
    this.filterByTheory = this.selectedSearchByTheory;
    this.$bvModal.hide(this.filterModal);
  }

  public cancelFiltering() {
    this.abortFilter();
    this.$bvModal.hide(this.filterModal);
  }

  public onFilterModalPopup() {
    this.$bvModal.show(this.filterModal);
  }

  public onCancelLivePayModalPopup(educationId: number, livePayPaymentId: number) {
    this.$bvModal.show(this.cancelLivePayModalId);
  }

  public onAbortCancelLivePay() {
    this.$bvModal.hide(this.cancelLivePayModalId);
  }

  private filterByLicenseClasses(services: Array<any>, selectedClasses: Array<string>, filterByTheory: boolean) {
    if (services.length > 0) {
      return services.filter((event: any) => {
        return (
          selectedClasses.length === 0 ||
          selectedClasses?.some((licenseClass: string) => {
            return event.licenseClass
              ?.toLowerCase()
              ?.split(",")
              .map((i: any) => i.trim())
              .includes(licenseClass.toLowerCase());
          }) ||
          (filterByTheory && event.licenseClass?.toLowerCase().indexOf("grundstoff") > -1)
        );
      });
    }
    return services;
  }

  // Reactive filter
  private get filteredOptions() {
    const educationEventTypes = this.selectedEducationEvent?.educationEventType || [];
    const currentDate = moment();

    const educationServices = this.filterByLicenseClasses(this.educationEvents, this.selectedLicenseClasses, this.filterByTheory);

    switch (educationEventTypes?.[0]) {
      case "All":
        return educationServices;
      case "Future":
        return educationServices.filter((evn: any) => moment(evn.date).isAfter(currentDate));
      case "Past":
        return educationServices.filter((evn: any) => moment(evn.date).isBefore(currentDate));
      default: {
        // const filtered = educationServices.filter((ev: any) => educationEventTypes.includes(ev.educationEventType));
        const selectedEducationEventStandardProductId = this.selectedEducationEvent?.standardProductId;
        const selectedEducationEventShouldIncludeAlsoStandardProductIds = this.selectedEducationEvent?.includeAlsoStandardProductIds;

        const filtered = educationServices.filter((ev: any) => {
          const matchesEventType = educationEventTypes.includes(ev.educationEventType);

          const matchesProductId = Array.isArray(selectedEducationEventShouldIncludeAlsoStandardProductIds)
            ? // is array check since most are undefined/not set
              selectedEducationEventShouldIncludeAlsoStandardProductIds.includes(ev.standardProductId)
            : false;

          return matchesEventType || matchesProductId;
        });

        if (this.selectedEducationEvent.standardProductId == BASIC_FEE_STANDARD) {
          return filtered.filter((ev: any) => ev.standardProductId === selectedEducationEventStandardProductId);
        } else if (this.selectedEducationEvent?.standardProductId == LEARNING_MATERIAL) {
          return filtered.filter((ev: any) => ev.standardProductId === selectedEducationEventStandardProductId);
        } else {
          return filtered;
        }
      }
    }
  }

  private get getCreatedByName() {
    const createdBy = this.selectedRow?.createdBy;
    if (createdBy) {
      return `${createdBy.firstName} ${createdBy.lastName}`;
    }
    return undefined;
  }
}
