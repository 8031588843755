import { Component } from "vue-property-decorator";
import {
  CANCELLATION_INVOICE,
  CUSTOM_BOOKING,
  DRIVING_LESSON,
  EMAIL,
  FINAL_INVOICE,
  NOTE,
  PARTIAL_INVOICE,
  PAYMENT,
  PAYMENT_REQUEST,
  PRACTICAL_EXAM,
  PRODUCT_BOOKING,
  STUDENT_DOCUMENT,
  THEORY_EXAM,
  THEORY_LESSON,
  PROOF_OF_TRAINING,
  LIVE_PAY_PAYMENT,
  CLASSIC_PAY_PAYMENT,
  COST_BEARER_PAYMENT,
  EXTERNAL_SERVICES_PACK,
  COST_BEARER_PAYMENT_REQUEST,
  COST_BEARER_INVOICE,
  COST_BEARER_NOTE,
  MESSAGE,
  COST_BEARER_CUSTOM_BOOKING,
} from "@/constants/EducationEventTypes";
import { mixins } from "vue-class-component";
import EducationEventMixin from "@/mixins/EducationEventMixin";
import ProductRequestMixin from "@/mixins/Request/ProductRequestMixin";
import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import StudentEducationRequestMixin from "@/mixins/Request/StudentEducationRequestMixin";
import EducationEventRequestMixin from "@/mixins/Request/EducationEventRequestMixin";
import PriceRequestMixin from "@/mixins/Request/PriceRequestMixin";
import DrivingLessonRequestMixin from "@/mixins/Request/DrivingLessonRequestMixin";
import PracticalExamRequestMixin from "@/mixins/Request/PracticalExamRequestMixin";
import TheoryExamRequestMixin from "@/mixins/Request/TheoryExamRequestMixin";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import PriceMixin from "@/mixins/PriceMixin";
import TabMixin from "@/mixins/TabMixin";
import LivePayRequestMixin from "@/mixins/Request/LivePayRequestMixin";
import { namespace } from "vuex-class";
import { COLORS_BY_STATUS } from "@/constants/Statuses";
import EmailRequestMixin from "@/mixins/Request/EmailRequestMixin";
import axios from "@/utils/axios";
import { AxiosResponse, AxiosError } from "axios";
import UserService from "@/services/UserService";

const LivePayModule = namespace("live-pay");

@Component
export default class EducationEventTypeMixin extends mixins(
  ViewSwitchMixin,
  PriceMixin,
  TabMixin,
  EducationEventMixin,
  ProductRequestMixin,
  StudentRequestMixin,
  StudentEducationRequestMixin,
  EducationEventRequestMixin,
  PriceRequestMixin,
  DrivingLessonRequestMixin,
  PracticalExamRequestMixin,
  TheoryExamRequestMixin,
  LivePayRequestMixin,
  EmailRequestMixin
) {
  public visibleBooking = false;
  public visibleNote = false;
  public visibleDocumentPreview = false;
  public visibleLearningMaterialForm = false;
  public visibleBasicFeeForm = false;
  public visibleDrivingLesson = false;
  public visibleTheoryLesson = false;
  public visiblePracticalExam = false;
  public visibleCustomBooking = false;
  public visibleCostBearerCustomBooking = false;
  public visibleNotePreview = false;
  public visiblePartialInvoicePreview = false;
  public visiblePartialInvoiceDocumentPreview = false;
  public visibleCostBearerInvoicePreview = false;
  public visibleCostBearerInvoiceDocumentPreview = false;
  public visibleClassicPayPreview = false;
  public visibleClassicPayDocumentPreview = false;
  public visibleCostBearerPaymentRequestPreview = false;
  public visibleCostBearerDocumentPreview = false;
  public visibleMainTableComponent = true;
  public visibleTheoryExam = false;
  public visibleStudentDocumentPreview = false;
  public visibleStudentDocumentPdfPreview = false;
  public visibleBasicAmountPreview = false;
  public visibleLivePay = false;
  public visibleEmailPreview = false;
  public visibleProofOfTrainingPreview = false;
  public visibleProofOfTrainingDocumentPreview = false;
  public visibleTheoryExamDocumentPreview = false;
  public visiblePaymentPreview = false;
  public visibleExternalServiceForm = false;
  public visibleExternalServicePreview = false;
  public visibleCostBearerNotePreview = false;
  public visibleMessagePreview = false;
  public visibleCostBearerPayment = false;

  public documentId = null;
  public theoryLesson = null;
  public pricesLoading = false;
  public customBooking = null;
  public costBearerCustomBooking = null;
  public selectedNote = null;
  public selectedPartialInvoice = null;
  public studentDocumentId = null;
  public selectedClassicPay = null;
  public selectedCostBearerPaymentRequest = null;
  public selectedCostBearerInvoice = null;
  public classicPayStudentDocumentId = null;
  public selectedStudentDocument = null;
  public selectedStudentDocumentId = null;
  public selectedCostBearerDocumentId = null;
  public selectedBasicAmount = null;
  public selectedProofOfTraining = null;
  public proofOfTrainingDocumentId = null;
  public theoryExamDocumentId = null;
  public selectedPayment = null;
  public selectedExternalService = null;
  public servicesWithPermissionForDelete = [THEORY_LESSON, DRIVING_LESSON, PRODUCT_BOOKING, CUSTOM_BOOKING, PAYMENT_REQUEST, COST_BEARER_CUSTOM_BOOKING];
  public servicesFreeForDelete = [NOTE, STUDENT_DOCUMENT, EMAIL, EXTERNAL_SERVICES_PACK, PROOF_OF_TRAINING];
  public serviceDeleteUrl = "";
  public removeLetterHead = false;
  public selectedCostBearerNote = null;
  public selectedMessage = null;
  public selectedCostBearerPayment = null;

  @LivePayModule.Action("livePayPaymentUpdateStatus")
  public livePayPaymentUpdateStatus!: (livePayPaymentId: number) => Promise<void>;

  @LivePayModule.Getter("getLivePayPaymentUpdateStatusSuccess")
  public getLivePayPaymentUpdateStatusSuccess!: boolean;

  public get permissions() {
    return UserService.getPermissions();
  }

  protected get hasPermissionToDeleteService() {
    return this.permissions.find((permission: any) => permission === "DELETE_BOOKED_SERVICES");
  }

  public get deleteServiceIsAllowed() {
    if (!this.selectedRow || !!this.serviceDeleteUrl === false) return false;
    const serviceType = this.selectedRow?.educationEventType;
    if (!serviceType) return false;
    if (this.servicesWithPermissionForDelete.includes(serviceType) && this.hasPermissionToDeleteService) {
      return true;
    }
    if (this.servicesFreeForDelete.includes(serviceType)) return true;
    return false;
  }

  public onRowClicked(ctx: any, fromCostBearer = false) {
    this.selectedRow = ctx;
    const studentId = ctx?.student?.id || +this.$route.params.id;
    const serviceId = ctx?.id;
    this.removeLetterHead = false;
    this.onCloseAllForms();
    switch (ctx.educationEventType) {
      case STUDENT_DOCUMENT:
        this.visibleStudentDocumentPreview = true;
        this.selectedStudentDocument = ctx;
        this.serviceDeleteUrl = "student-documents/" + serviceId;
        break;
      case DRIVING_LESSON:
        this.visibleDrivingLesson = true;
        this.fetchDrivingLesson(ctx.id);
        this.serviceDeleteUrl = "driving-lessons/" + serviceId;
        break;
      case THEORY_LESSON:
        this.theoryLesson = ctx;
        this.visibleCustomBooking = false;
        this.visibleTheoryLesson = true;
        this.serviceDeleteUrl = `theory-lesson/student-theory-lesson?theoryLessonId=${serviceId}&studentId=${studentId}`;
        break;
      case PRACTICAL_EXAM:
        this.visiblePracticalExam = true;
        this.fetchPracticalExam(ctx.id);
        this.serviceDeleteUrl = "";
        break;
      case CUSTOM_BOOKING:
        if (!fromCostBearer || (studentId && fromCostBearer)) {
          this.customBooking = ctx;
          this.visibleCustomBooking = true;
          this.serviceDeleteUrl = "custom-booking/" + serviceId;
        }
        break;
      case NOTE:
        this.selectedNote = ctx;
        this.visibleNotePreview = true;
        this.serviceDeleteUrl = "student-note/" + serviceId;
        break;
      case FINAL_INVOICE:
      case CANCELLATION_INVOICE:
      case PARTIAL_INVOICE:
        this.selectedPartialInvoice = ctx;
        this.visiblePartialInvoicePreview = true;
        this.visiblePartialInvoiceDocumentPreview = false;
        this.serviceDeleteUrl = "";
        break;
      case THEORY_EXAM:
        this.visibleTheoryExam = true;
        this.fetchTheoryExam(ctx.id);
        this.visibleTheoryExamDocumentPreview = false;
        this.serviceDeleteUrl = "";
        break;
      case PAYMENT_REQUEST:
        this.selectedClassicPay = ctx;
        this.visibleClassicPayPreview = true;
        this.visiblePartialInvoiceDocumentPreview = false;
        this.serviceDeleteUrl = "payment-requests/" + serviceId;
        break;
      case COST_BEARER_PAYMENT_REQUEST:
        this.selectedCostBearerPaymentRequest = ctx;
        this.visibleCostBearerPaymentRequestPreview = true;
        this.visibleCostBearerDocumentPreview = false;
        this.serviceDeleteUrl = "";
        break;
      case COST_BEARER_INVOICE:
        this.selectedCostBearerInvoice = ctx;
        this.visibleCostBearerInvoicePreview = true;
        this.visibleCostBearerInvoiceDocumentPreview = false;
        this.serviceDeleteUrl = "";
        break;
      case PRODUCT_BOOKING:
        this.selectedBasicAmount = ctx;
        this.visibleBasicAmountPreview = true;
        this.serviceDeleteUrl = "student-booked-product/" + serviceId;
        break;
      case PAYMENT:
        this.visibleLivePay = true;
        this.fetchLivePayPayment(ctx.id);
        this.serviceDeleteUrl = "";
        break;
      case LIVE_PAY_PAYMENT:
        this.visibleLivePay = true;
        this.fetchLivePayPayment(ctx.id);
        this.serviceDeleteUrl = "";
        break;
      case EMAIL:
        this.visibleEmailPreview = true;
        this.findEmail(ctx.id);
        this.serviceDeleteUrl = "emails/" + serviceId;
        break;
      case PROOF_OF_TRAINING:
        this.selectedProofOfTraining = ctx;
        this.visibleProofOfTrainingPreview = true;
        this.visibleProofOfTrainingDocumentPreview = false;
        this.serviceDeleteUrl = "";
        // this.serviceDeleteUrl = "proof-of-training/"+serviceId;
        break;
      case CLASSIC_PAY_PAYMENT:
        this.visiblePaymentPreview = true;
        this.selectedPayment = ctx;
        this.serviceDeleteUrl = "";
        break;
      case EXTERNAL_SERVICES_PACK:
        this.selectedExternalService = ctx;
        this.visibleExternalServicePreview = true;
        this.serviceDeleteUrl = "external-services-packs/" + serviceId;
        break;
      case COST_BEARER_NOTE:
        this.selectedCostBearerNote = ctx;
        this.visibleCostBearerNotePreview = true;
        break;
      case MESSAGE:
        this.selectedMessage = ctx;
        this.visibleMessagePreview = true;
        this.serviceDeleteUrl = "";
        break;
      case COST_BEARER_CUSTOM_BOOKING:
        this.costBearerCustomBooking = ctx;
        this.visibleCostBearerCustomBooking = true;
        this.serviceDeleteUrl = "cost-bearer-custom-booking/" + serviceId;
        break;
      case COST_BEARER_PAYMENT:
        this.visibleCostBearerPayment = true;
        this.selectedCostBearerPayment = ctx;
        this.serviceDeleteUrl = "";
        break;
    }
    this.documentId = ctx.id;
  }

  protected onCloseAllForms(): void {
    this.visibleNote = false;
    this.visibleBooking = false;
    this.visibleBasicFeeForm = false;
    this.visibleLearningMaterialForm = false;
    this.visibleDrivingLesson = false;
    this.visibleDocumentPreview = false;
    this.visibleTheoryLesson = false;
    this.visiblePracticalExam = false;
    this.visibleCustomBooking = false;
    this.visibleCostBearerCustomBooking = false;
    this.visibleNotePreview = false;
    this.visiblePartialInvoicePreview = false;
    this.visiblePartialInvoiceDocumentPreview = false;
    this.visibleTheoryExam = false;
    this.visibleClassicPayPreview = false;
    this.visibleCostBearerPaymentRequestPreview = false;
    this.visibleCostBearerInvoicePreview = false;
    this.visibleCostBearerInvoiceDocumentPreview = false;
    this.visiblePartialInvoiceDocumentPreview = false;
    this.visibleStudentDocumentPreview = false;
    this.visibleStudentDocumentPdfPreview = false;
    this.visibleBasicAmountPreview = false;
    this.visibleLivePay = false;
    this.visibleEmailPreview = false;
    this.visibleProofOfTrainingPreview = false;
    this.visibleProofOfTrainingDocumentPreview = false;
    this.visibleTheoryExamDocumentPreview = false;
    this.visiblePaymentPreview = false;
    this.visibleExternalServiceForm = false;
    this.visibleExternalServicePreview = false;
    this.visibleCostBearerNotePreview = false;
    this.visibleMessagePreview = false;
    this.visibleCostBearerPayment = false;
  }

  public onDeleteSelectedService() {
    if (!this.serviceDeleteUrl) return;
    return axios
      .delete(this.serviceDeleteUrl)
      .then(({ data }: AxiosResponse) => {
        return data;
      })
      .catch((err: AxiosError) => {
        const message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
        return message;
      });
  }

  public onPartialInvoiceDocumentPreview(documentId: any, removeLetterHead: any): void {
    this.studentDocumentId = documentId;
    this.removeLetterHead = removeLetterHead;
    this.visiblePartialInvoiceDocumentPreview = true;
    this.visibleMainTableComponent = false;
  }

  public onPartialInvoiceDocumentPreviewClose(): void {
    this.visiblePartialInvoiceDocumentPreview = false;
    this.visibleMainTableComponent = true;
    this.visiblePartialInvoicePreview = true;
  }

  public onClassicPayDocumentPreview(documentId: any, removeLetterHead: any): void {
    this.classicPayStudentDocumentId = documentId;
    this.removeLetterHead = removeLetterHead;
    this.visibleClassicPayDocumentPreview = true;
    this.visibleMainTableComponent = false;
  }

  public onClassicPayDocumentPreviewClose(): void {
    this.visibleClassicPayDocumentPreview = false;
    this.visibleMainTableComponent = true;
    this.visibleClassicPayPreview = true;
  }

  public onCostBearerDocumentPdfPreview(documentId: any, removeLetterHead: any): void {
    this.selectedCostBearerDocumentId = documentId;
    this.removeLetterHead = removeLetterHead;
    this.visibleCostBearerDocumentPreview = true;
    this.visibleMainTableComponent = false;
  }

  public onCostBearerPaymentRequestPdfPreviewClose(): void {
    this.visibleCostBearerDocumentPreview = false;
    this.visibleMainTableComponent = true;
    this.visibleCostBearerPaymentRequestPreview = true;
  }

  public onCostBearerInvoiceDocumentPdfPreview(documentId: any, removeLetterHead: any): void {
    this.selectedCostBearerDocumentId = documentId;
    this.removeLetterHead = removeLetterHead;
    this.visibleCostBearerInvoiceDocumentPreview = true;
    this.visibleMainTableComponent = false;
  }

  public onCostBearerInvoicePdfPreviewClose(): void {
    this.visibleCostBearerInvoiceDocumentPreview = false;
    this.visibleMainTableComponent = true;
    this.visibleCostBearerInvoicePreview = true;
  }

  public onStudentDocumentPdfPreview(documentId: any, removeLetterHead: any): void {
    this.selectedStudentDocumentId = documentId;
    this.removeLetterHead = removeLetterHead;
    this.visibleStudentDocumentPdfPreview = true;
    this.visibleMainTableComponent = false;
  }

  public onStudentDocumentPdfPreviewClose(): void {
    this.visibleStudentDocumentPdfPreview = false;
    this.visibleMainTableComponent = true;
    this.visibleStudentDocumentPreview = true;
  }

  public onProofOfTrainingDocumentPreview(documentId: any, removeLetterHead: any): void {
    this.proofOfTrainingDocumentId = documentId;
    this.removeLetterHead = removeLetterHead;
    this.visibleProofOfTrainingDocumentPreview = true;
    this.visibleMainTableComponent = false;
  }

  public onProofOfTrainingDocumentPreviewClose(): void {
    this.visibleProofOfTrainingDocumentPreview = false;
    this.visibleMainTableComponent = true;
    this.visibleProofOfTrainingPreview = true;
  }

  public onTheoryExamDocumentPreview(documentId: any, removeLetterHead: any): void {
    this.theoryExamDocumentId = documentId;
    this.removeLetterHead = removeLetterHead;
    this.visibleTheoryExamDocumentPreview = true;
    this.visibleMainTableComponent = false;
  }

  public onTheoryExamDocumentPreviewClose(): void {
    this.visibleTheoryExamDocumentPreview = false;
    this.visibleMainTableComponent = true;
    this.visibleTheoryExam = true;
  }

  public get gridTemplateStyle() {
    if (this.visiblePartialInvoiceDocumentPreview && !this.visibleMainTableComponent) {
      return `grid-template-columns: 1fr 2fr; grid-gap: 20px`;
    } else if (this.visibleClassicPayDocumentPreview && !this.visibleMainTableComponent) {
      return `grid-template-columns: 1fr 2fr; grid-gap: 20px`;
    } else if (this.visibleStudentDocumentPdfPreview && !this.visibleMainTableComponent) {
      return `grid-template-columns: 1fr 2fr; grid-gap: 20px`;
    } else if (this.visibleProofOfTrainingDocumentPreview && !this.visibleMainTableComponent) {
      return `grid-template-columns: 1fr 2fr; grid-gap: 20px`;
    } else if (this.visibleTheoryExamDocumentPreview && !this.visibleMainTableComponent) {
      return `grid-template-columns: 1fr 2fr; grid-gap: 20px`;
    } else if (this.visibleCostBearerDocumentPreview && !this.visibleMainTableComponent) {
      return `grid-template-columns: 1fr 2fr; grid-gap: 20px`;
    } else if (this.visibleCostBearerInvoiceDocumentPreview && !this.visibleMainTableComponent) {
      return `grid-template-columns: 1fr 2fr; grid-gap: 20px`;
    }
    return `grid-template-columns: 2fr 1fr; grid-gap: 20px`;
  }

  protected vBindIcon(data: any): any {
    let typeIcon = "";
    const eType = data.item.educationEventType;
    if (eType === STUDENT_DOCUMENT) {
      typeIcon = "file";
    } else if (eType === PARTIAL_INVOICE || eType === FINAL_INVOICE) {
      typeIcon = "file-plus";
    }

    const driveBuzzVisibleIcon = {
      id: 108,
      type: STUDENT_DOCUMENT,
      PARTIAL_INVOICE,
      FINAL_INVOICE,
      icon: typeIcon,
    };

    const icon = data.item.driveBuzzVisible === false ? driveBuzzVisibleIcon : this.getStatusIcon(data);
    const iconPrefix = icon.custom ? "fak" : "fat";

    const bind = {
      icon: data.item.driveBuzzVisible === false ? ["fas", icon.icon] : [iconPrefix, icon.icon],
      class: icon.class ?? null,
    };

    if (data.item && data.item.livePayPaymentStatus) {
      // @ts-ignore
      const color = COLORS_BY_STATUS[data.item.livePayPaymentStatus];

      Object.assign(bind, {
        style: { color: color },
      });
    }
    return bind;
  }

  public get eventsFilterOption() {
    return [
      {
        name: this.$t("event_types.all"),
        id: 1,
        educationEventType: ["All"],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.driving_lesson"),
        id: 2,
        educationEventType: [DRIVING_LESSON],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.theory_lesson"),
        id: 3,
        educationEventType: [THEORY_LESSON],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.exams"),
        id: 4,
        educationEventType: [THEORY_EXAM, PRACTICAL_EXAM],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.payment"),
        id: 5,
        educationEventType: [PAYMENT, CLASSIC_PAY_PAYMENT, LIVE_PAY_PAYMENT, COST_BEARER_PAYMENT],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.invoices"),
        id: 6,
        educationEventType: [FINAL_INVOICE, PARTIAL_INVOICE, CANCELLATION_INVOICE],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.basic_fee"),
        id: 7,
        educationEventType: [PRODUCT_BOOKING],
        standardProductId: 300,
      },
      {
        name: this.$t("event_types.learning_material"),
        id: 8,
        educationEventType: [PRODUCT_BOOKING],
        standardProductId: 502,
      },
      {
        name: this.$t("event_types.documents"),
        id: 9,
        educationEventType: [STUDENT_DOCUMENT, PROOF_OF_TRAINING, PAYMENT_REQUEST],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.email"),
        id: 10,
        educationEventType: [EMAIL],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.notes"),
        id: 11,
        educationEventType: [NOTE],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.other_cost"),
        id: 12,
        educationEventType: [CUSTOM_BOOKING],
        standardProductId: null,
        includeAlsoStandardProductIds: [700],
      },
      {
        name: this.$t("sidebar.messaging"),
        id: 15,
        educationEventType: [MESSAGE],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.history"),
        id: 13,
        educationEventType: ["Past"],
        standardProductId: null,
      },
      {
        name: this.$t("event_types.events"),
        id: 14,
        educationEventType: ["Future"],
        standardProductId: null,
      },
    ];
  }
}
